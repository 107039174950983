<script lang="ts">
import { Image } from '@/Types/Image';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css'
import arrowleft from '@/assets/chevron_left.svg'
import arrowRight from '@/assets/chevron_right.svg'
import { ToDate } from '@/utils/formats';
export default {
    name: "ImageSlide",
    components: {
        Splide,
        SplideSlide,
        SplideTrack
    },
    props: {
        images: Array<Image>,
        dateField: {type: String, default: null},
        marginBottom: {type: String, default: '6rem'},
        borderRadiusImage: {type: Boolean, default: true},
        classes: {type: String, default: ''},
    },
    data() {
        return {
            arrowRight,
            arrowleft,
            slickOptions: {
                slidesToShow: 3,
                // Any other options that can be got from plugin documentation
            },
            ToDate
        };
    },
}

</script>

<template>
    <div class='image-slider-wrapper h-fit w-full max-w-full overflow-hidden' :class="borderRadiusImage ? 'border-main' : ''">
        <Splide :hasTrack="false" :options="{ rewind: true }">
            <SplideTrack>
                <template v-for="image in images">
                    <SplideSlide>
                        <div class='aspect-video relative'>
                            <div class='slide-image h-full w-full bg-cover' :class="classes" :style="`background-image: url('${image.url}')`">
                                <div v-if="image.label" :class="`image-label mb-[${marginBottom}]`">
                                    {{ToDate(image.label)}}
                                </div>
                                <div v-if="dateField" :class="`image-label mb-[${marginBottom}]`">
                                    {{ToDate(image[dateField])}}
                                </div>
                            </div>
                        </div>   
                    </SplideSlide>
                </template>
            </SplideTrack>
            <div class="splide__arrows">
                <button class="splide__arrow splide__arrow--prev">
                    <img :src="arrowleft"  alt="" />
                </button>
                <button class="splide__arrow splide__arrow--next">
                    <img :src="arrowRight"  alt="" />
                </button>
            </div>
        </Splide>
    </div>


</template>

<style scoped>
.image-slider-wrapper {
    position: relative;
}
.slick-slider {
    width: 100%;
}
.slick-list {
    border-radius: .5rem;
    width: 100%;
}
.slide-image {
    position: relative;
    background-position: center center;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.image-label {
    display: flex;
    width: fit-content;
    justify-content: space-between;
    color: #fff;
    padding: 1rem;
    text-shadow: 1px 1px 2px #000;
    background-color: rgba(0,0,0,.3);
    border-radius: 1rem;
}
.arrows-wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 12%;
    z-index: 99999;
}
button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    background-color: rgba(216, 216, 216, 0.5);
    border-radius: 10rem;
}
</style>