<script lang="ts">

export default {
    name: "SenhaCheckList",
    props: {
        checks: {type: Array, default: []},
        senhasIguais:{type: Boolean, default: false},
        defaultColor: {type: String, default: 'grey-label'},
        activeStyle: {type: String, default: 'dark-blue font-bold'},
    }
}
</script>


<template>
        <div class="mt-[2rem] ml-[1.5rem] leading-[1.8rem] text-[1.2rem]" :class="`${defaultColor}`">
            <div class="password-1" :class="checks.includes('password-1') ? activeStyle : ''">* 8 ou mais caracteres</div>
            <div class="password-2" :class="checks.includes('password-2') ? activeStyle : ''">* 1 ou mais letras maiúsculas</div>
            <div class="password-3" :class="checks.includes('password-3') ? activeStyle : ''">* 1 ou mais caracteres especiais (!@*/)</div>
            <div class="password-4" :class="checks.includes('password-4') ? activeStyle : ''">* 1 ou mais números</div>
            <div v-if="senhasIguais" class="password-5" :class="checks.includes('password-5') ? activeStyle : ''">* Senhas iguais</div>
        </div>
</template>