<script lang="ts">
import {getParams,clearParams} from '@/services/Browser';

export default {
    name: 'Acordion',
    props: {
        titulo: {type: String, default: ''},
        conteudo: {type: String, default: ''},
        open:{ type: Boolean, default: false},
        tituloSize: {type: String, default: "text-[2.8rem]"}, 
        borderWidth: {tuype: String, default: "border-[1px]"}
        
    },
    data() {
        return {            
            opened: false,

            totalmenteAberta: false,
        }
    },
    methods: {
        async openAcordion(){
            this.opened = !this.opened;
            if(!this.totalmenteAberta){
                setTimeout(() => {this.totalmenteAberta = true;}, 130); return; 
            } 
            this.totalmenteAberta = false;
        }
    },
    mounted(){
        if(this.open) this.openAcordion();
        const openAcordion = getParams('acord');
        if(openAcordion == this.titulo){
            this.openAcordion();
            clearParams('acord')
        }
    }
    
}
</script>

<template>
        <div class="accordion overflow-hidden" :class="{'mb-[1.5rem]': opened}"> 
            <div :class="`accordion-header flex justify-between items-center font-normal pointer ${tituloSize}`" 
                    @click="openAcordion()" >
                   <div class="flex gap-4">
                        <slot name="header"/>
                       <p :class="{'font-bold' : opened}"> {{ titulo }}</p>
                   </div>
                    <i :class="{'rotate-180': opened}" class="fas fa-angle-down clr-gry arrow-toggle"></i>
            </div>
            <div :class="`${borderWidth} border-solid my-[1.5rem] border-[#001a42]`" ></div>

            <div class="accordion-content max-h-[0rem]" 
                :class="{'active' : opened, 'overflow-y-auto' : totalmenteAberta}"
                ref="content">
                <slot name="body"/>
            </div>
        </div>
</template>

<style scoped>
    .accordion-content {
        transition: max-height 0.2s ease-out;
    }

    ::-webkit-scrollbar {
        width: .4.5rem;
    }

    ::-webkit-scrollbar-track {
        background: #fff;
    }

    .active{
        max-height: 50rem !important;
    }

</style>