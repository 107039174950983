import { Router } from "vue-router";
import * as Sentry from '@sentry/vue';
import { Vue } from "@sentry/vue/types/types";

const initSentry = (app: Vue, router: Router) => {
    Sentry.init({
        app,
        dsn: "https://33fdbaeb04791d466f753a999a367f97@o319536.ingest.sentry.io/4505873678008320",
        integrations: [
          new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [
              "https://staging.urbe.me/.*",
              "https://www.staging.urbe.me/.*",
              "https://www.urbe.me/.*",
              "https://urbe.me/.*",
            ],
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          }),
        ],
        environment: import.meta.env.MODE,
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      });      
}

export default initSentry