<script lang="ts">
import { PropType, defineAsyncComponent, defineComponent } from 'vue';
import ModalBase from './ModalBase.vue';
import { InputType } from '@/Types/InputTypes';
import SubmitButton from '@/Components/SubmitButton.vue';
import { MaskType } from '@/Types/MaskTypes';


const InputField = defineAsyncComponent(() =>
  import('@/Components/InputField.vue')
)

export default defineComponent({
    name: "ModalParcela",
    props: {
        close: {type: Function as PropType<()=>void>, default: ()=>{}},
        order: {type: Number, default: 0},
        instance: {type: Object, required: true},
        callbackForm: {type: Function, required: true}
    },
    data(){


        return {
            InputType,
            MaskType
        }
    },
    components: {
        ModalBase, InputField, SubmitButton, 
    },
    
})
</script>

<template>
    <ModalBase @close="close" :order="order" classes="bg-white border-main flex justify-center items-center relative p-4">
        <div @click="close" class="dark-blue absolute top-4 right-5 cursor-pointer">
            <i class="fal fa-times"></i>
        </div>
        <div class="content p-main !py-14">
            <div>
                <h1 class="font-xl flex justify-center">Editar Parcela - {{ instance.referencia }}</h1>
            </div>
            <div class="text-center">
                <form action="POST" enctype='multipart/form-data' class="mb-main" @submit.prevent="(event)=> callbackForm(event)">
                <div class="grid grid-cols-4">
                    
                        <InputField
                            name="data"
                            label="Data"
                            label-classes="pt-[0rem] grey-label font-small"   
                            v-model:value="instance.data"
                            :type="InputType.TEXT"
                            :mask="MaskType.DATA_MIN"
                            placeholder=""
                            :isRequired="true"
                            :colSpan="2"
                        />
                        <InputField
                            :itemsCenter="true"
                            :noMargin="true"
                            name="pagamento_realizado"
                            v-model:value="instance.status"
                            label="Pagamento Realizado"
                            :colSpan="2"
                            :is-selected="instance.status"
                            :type="InputType.CHECKBOX"
                            placeholder=""
                            :isRequired="false"
                        />
                    </div>       
                    <SubmitButton 
                        :disabled="false" 
                        text="Editar" 
                        :noBorder="true" 
                        :noBlock="true" 
                        :no-padding="true"
                        class="whitespace-nowrap px-4 mt-10 justify-center" />
                </form>

            </div>
        </div>
    </ModalBase>
</template>
