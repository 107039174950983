import { IMAGE_MAX_SIZE, REQUEST_MAX_SIZE } from "../Types/InputTypes";
import { ToDate } from "../utils/formats";
import { PostResponse } from "./Api";
import ModalService from "./ModalService";

export interface FormModel {
    errors?: any | null;
}

export const ObjectToFormData = (object: any) => {
    const data = new FormData();
    for (let key in object) {
        if(object[key] && object[key].constructor == FileList) {
            for(let file of object[key]) {
                data.append(key, file)
            }
            continue
        }
        if(object[key] && (object[key].constructor == Array || object[key].constructor == Object)) {
            data.append(key, JSON.stringify(object[key]));
            continue
        }
        data.append(key, object[key]);
    }
    return data;
}

const clearErrors = (form: HTMLFormElement) => {
    if(!form) return;
        
    form.querySelectorAll('.error-field').forEach((element) => {
        element.innerHTML = '';
        if(!element.classList.contains('hidden'))
            element.classList.add('hidden');
    })
}

const addErrors = (form: HTMLFormElement, errors: any) => {
    if(!form) return;   

    for (let key in errors) {
        const element = form.querySelector(`#${key}-error`);
        for (let error of errors[key]) {
            const p = document.createElement('p');
            p.innerHTML = error.message;
            element?.appendChild(p);
        }
        element?.classList.remove('hidden');
    }
}

export const PostData = async (event: SubmitEvent | undefined, object: Object, postFunction: Function, callback: (success: boolean) => any, show_modal=true) => {

    if(event)
        clearErrors(event.target as HTMLFormElement);


    const data: FormData = ObjectToFormData(object);
    const response: PostResponse = await postFunction(data);
    
    if(response?.success) {
        callback(true);
        //MODAL COM NOME DE ERROR POREM RETORNA MENSAGEM DE SUCESSO :P
        if(show_modal)
            ModalService.createError(response.data.message || "Dados Salvos com Sucesso.")

        return response?.data; 
    }

    if(response?.errors) {
        try {
            if(response.errors?.__all__) {
                ModalService.createError(Object.values(response.errors.__all__).map(({message} : any)=>message))
            }
        }
        catch {
            console.warn("Ocorreu um erro")
        }
            
        if(event)
            addErrors(event.target as HTMLFormElement, response.errors);
    }
    callback(false);
}

type Error = {message: string}
type Errors = Record<string, Error[]>; 

export interface IFormInstance {
    id?: number;
    editable?: boolean;
    errors?: Errors | null;
}

export type Key = keyof IFormInstance;

export abstract class ModelForm {
    instance!: IFormInstance;
    alwaysEditable: boolean = false
    createFunction: (data: FormData, params: null | object)=>Promise<PostResponse>;
    updateFunction: (data: FormData, params: null | object)=>Promise<PostResponse>;
    newObject: boolean;
    container: string;
    containerUpdate: string | null;
    params: object | null;
    numbers?: string[];
    percentages?: string[];
    dates?: string[];
    multipleFilesFields?: string[];
    multipleFilesUploadFunction?: (data: FormData)=>Promise<PostResponse>;
    notEditableMessage: string = "Não é possível editar as informações";

    constructor(
            createFunction: (data: FormData, params: null | object)=>Promise<PostResponse>,
            updateFunction: (data: FormData, params: null | object)=>Promise<PostResponse>,
            container: string = "",
            containerUpdate: string | null = null,
            params: object | null = {},
        ) {
        this.createFunction = createFunction;
        this.updateFunction = updateFunction;
        this.container = container;
        this.containerUpdate = containerUpdate;
        this.params = params;
        this.newObject = false;
    }

    static removeTrailingZeros(value: string) {
        return value.replace(/(\.[0-9]*[1-9])0+$|\.0*$/,'$1');
    }

    static toNumber(masked_value: string | number) {
        if(!masked_value) return 0;
        if(typeof(masked_value) == "number") return masked_value;
        let number_value: number = Number(masked_value.replace(/\./g, "").replace(",", ".").replace(/[^\d.-]/g, ""));
        return number_value;
    }

    static toDecimal(masked_value: string | number): string {
        let unmasked_value = ModelForm.toNumber(masked_value)/100;
        if(!unmasked_value) return String(unmasked_value);
        let return_value = String(unmasked_value)
        const splited = return_value.split('.');
        if (splited.length == 2) {
            if(splited[1].length > 8) {
                return_value = unmasked_value.toFixed(8)
            }
        }
        return_value = ModelForm.removeTrailingZeros(return_value)
        return return_value;
    }

    loadInstance(instance: IFormInstance | any | null = null): void {
        if (!instance) {
            this.instance = {};
            this.newObject = true;
            return;
        }

        if (this.numbers) {
            for(let number of this.numbers) {
                if (instance[number] != 0 && (!instance[number] || instance[number] == 'null'))
                    instance[number] = null
                else
                    (instance[number as Key] as any) = String(instance[number as Key]).replace('.', ',');
            }
        }
        if (this.percentages) {
            for(let percentage of this.percentages) {
                if (instance[percentage] != 0 && (!instance[percentage] || instance[percentage] == 'null'))
                    instance[percentage] = null
                else
                    (instance[percentage as Key] as any) = String(instance[percentage as Key]).replace('.', ',');
            }
        }

        this.instance = instance;
        this.newObject = false;
    }

    isEditable(): boolean {
        return this.instance?.editable ?? true;
    }

    addError(attribute: string, message: string): void {
        if(!this.instance.errors) this.instance.errors = {};
        if(attribute == '') attribute = "__all__"
        if(!this.instance.errors[attribute as keyof Object]) this.instance.errors[attribute as keyof Object] = [];
        this.instance.errors[attribute as keyof Object].push({message});
    }

    isValid(): boolean {
        return !(this.instance.errors && Object.keys(this.instance.errors).length > 0);
    }

    serialize(): FormData {
        const cleanedObject = this.clean();
        const data = new FormData();
        for (let key in cleanedObject) {
            const object = cleanedObject[key as Key] as unknown; 
            if(object == null) continue;
            if(object && object?.constructor == FileList) {
                if (this.multipleFilesFields && this.multipleFilesFields.includes(key))
                    continue
                
                const fileList = (object as any);
                for(let file of fileList) {
                    data.append(key, file)
                }
                continue
            }
            if(object && (object?.constructor == Array || object?.constructor == Object)) {
                data.append(key, JSON.stringify(object));
                continue
            }
            data.append(key, object as string);
        }
        return data;
    }

    clean(): IFormInstance {
        if(!this.isEditable() && !this.alwaysEditable)
            this.addError("", this.notEditableMessage);

        const cleaned_data = {...this.instance};
        if(this.numbers) {
            for (let number of this.numbers) {
                const element = cleaned_data[number as Key] as unknown;
                if (element == null || element == undefined || element == '')
                    continue

                (cleaned_data[number as Key] as unknown) = ModelForm.toNumber(element as string);
            }
        }
        if(this.percentages) {
            for (let number of this.percentages) {
                const element = cleaned_data[number as Key] as unknown; 
                if (element == null || element == undefined || element == '' || element == '%') {
                    (cleaned_data[number as Key] as unknown) = null
                    continue
                }
                (cleaned_data[number as Key] as unknown) = ModelForm.toDecimal(cleaned_data[number as Key] as number);
            }
        }

        if (this.dates) {
            for (let date of this.dates) {
                const element = cleaned_data[date as Key] as unknown;
                if (element && String(element).length != 10) {

                    if (String(element).indexOf('/') == -1 && typeof element == 'string') {
                        const dia = element.substring(0, 2); 
                        const mes = element.substring(2, 4);
                        const ano = element.substring(4, 8);
                        (cleaned_data[date as Key] as unknown) = [dia, mes, ano].join('/')
                        continue
                    }

                    try {
                        (cleaned_data[date as Key] as unknown) = ToDate(String(element), true, true);
                    } catch (error) {
                        console.error("Campo inválido")
                    } 
                }
            }
        }
        
        const props = Object.getOwnPropertyNames(Object.getPrototypeOf(this));
        props.forEach(prop=>{
            if(prop.startsWith("clean_")) {
                try {
                    //@ts-ignore
                    cleaned_data[prop.replace("clean_", '') as keyof IFormInstance] = this[prop as keyof ModelForm]();
                } catch(error) {
                    console.error("Invalid", error)
                }
            }
        })
        return cleaned_data;
    };

    clearErrors = (form: HTMLFormElement) => {
        if(!form) return;
        form.querySelectorAll('.error-field').forEach((element) => {
            element.innerHTML = '';
            if(!element.classList.contains('hidden'))
                element.classList.add('hidden');
        })
        this.instance.errors = null;
    }

    showErrorField(mensagem: string, element: any) {
        const p = document.createElement('p');
        p.classList.add("form-error")
        p.innerHTML = mensagem;
        element?.appendChild(p);
    }

    showErrors({target}: Event, err: Errors | null = null, showModalError: boolean = true) {
        if(!target) return;
        const errors: Errors | null = err ? err : this.instance.errors!;
        if(!errors) return;
        for (let key in errors) {
            const element = (target as HTMLFormElement).querySelector(`#${key}-error`);
            if (Array.isArray(errors[key])) {
                for (let error of errors[key]) {
                    this.showErrorField(error.message, element)
                }
            } else {
                this.showErrorField((errors[key] as any), element)
            }
            element?.classList.remove('hidden');
        }
        let messages = ["O formulário contém erros de envio!"]
        if(errors['__all__']) {
            messages = messages.concat(Object.values(errors['__all__']).map(({message} : any)=>message))
        }
        
        showModalError && ModalService.createError(messages)
        this.moveToFirstError(target as HTMLFormElement)
    }

    moveToFirstError(form: HTMLFormElement) {
        const firstErrorElement: HTMLElement | null = form.querySelector(".form-error");
        let container;
        try {
            if (this.newObject || this.containerUpdate == null)
                container = document.querySelector(this.container)
            else
                container = document.querySelector(this.containerUpdate)
            
        } catch {
            container = null;
        }
        if (!firstErrorElement || !container)
            return;

        const offsetElement = firstErrorElement.parentElement?.parentElement
        if (!offsetElement)
            return
        
        container.scrollTop = offsetElement.offsetTop;
    }

    isLoading(active: boolean, { target }: Event) {
        if (!target) return
        const text = (target as HTMLFormElement).querySelector(".salvar-text")

        if(!text) return;

        const loading = (target as HTMLFormElement).querySelector(".save-loading")!

        if (active) {
            text.classList.add("hidden");
            loading.classList.remove("hidden")
        } else {
            text.classList.remove("hidden")
            loading.classList.add("hidden")
        }
    }

    async uploadMultipleFiles(): Promise<PostResponse> {
        if (!this.multipleFilesUploadFunction || !this.multipleFilesFields)
            return new Promise((res, rej)=>{res({success: true})})

        const data = {} as any
        for (let field of this.multipleFilesFields) {
            data[field as keyof Object] = this.instance[field as Key];
        }

        
        let response;
        let currentListSize = 0;
        let formData = new FormData();
        formData.append('id', String(this.instance.id))

        try {
        for (let fileList in data) {
            for (let file of data[fileList] as any) {
                
                if (currentListSize + file.size >= REQUEST_MAX_SIZE) {
                    response = await this.multipleFilesUploadFunction(formData);
                    if (!response?.success)
                        return response

                    currentListSize = 0;
                    formData = new FormData();
                    formData.append('id', String(this.instance.id))
                }
                currentListSize += file.size;
                formData.append(fileList, file)
            }
        }
        } catch {}

        if (currentListSize > 0)
            return await this.multipleFilesUploadFunction(formData);

        return new Promise((res, rej)=>{res({success: true})})
    }

    async form_valid(
        event: Event | null,
        cleanedData: FormData,
        callback: (success: boolean, data?: any) => void,
        multiple: boolean = false,
        showModal: boolean = true,
        inverShowModal: boolean = false
    ) {
        let response;
        if (event)
            this.isLoading(true, event)
        
        if (!multiple) {
            const saveFunction = this.newObject ? this.createFunction : this.updateFunction;
            if(!saveFunction) return;
            response = await saveFunction(cleanedData, this.params);

        }        

        if(this.multipleFilesFields) {
            response = await this.uploadMultipleFiles();
        }
        if (event)
            this.isLoading(false, event)
        
        if(response?.success) {
            callback(true, response?.data);
            let mensagem = 'Dados Salvos com Sucesso.'
            if (response?.data && 'message' in response.data) {
                mensagem = (response.data.message as string)
            }
            //MODAL COM NOME DE ERROR POREM RETORNA MENSAGEM DE SUCESSO :P
            if (showModal)
                ModalService.mensagem(mensagem)

            return response?.data; 
        }
        if(response?.errors && event) {
            if(inverShowModal){
                showModal = !showModal
            }
            this.showErrors(event, response.errors, showModal);
        }
        callback(false);
    }

    form_invalid(event: Event | null, callback: (success: boolean) => void): void {
        if (event)
            this.showErrors(event)
        
        callback(false);
    }

    async save(
        event: Event | null,
        callback: (success: boolean, data?: any)=>void,
        multiple: boolean = false,
        showModal: boolean = true,
        inverShowModal: boolean = false
    ): Promise<any> {
        if (event)
            this.clearErrors(event.target as HTMLFormElement);

        const data = this.serialize();
        if(this.isValid()) {
            return await this.form_valid(event, data, callback, multiple, showModal,inverShowModal);
        }
        else
            this.form_invalid(event, callback);
        
        return
    }
}