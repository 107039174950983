import { IFormInstance, ModelForm } from "@/services/Form";

export interface IDadosLogin extends IFormInstance {
    username: string;
    password: string;
}


export class DadosLoginForm extends ModelForm {
    instance!: IDadosLogin;
}

export interface IDados2FA extends IFormInstance {
    username: string;
    one_time_password: string;
    password: string;
}

export class Dados2FAForm extends ModelForm {
    instance!: IDados2FA;
}

export interface ICadastroInvestidor extends IFormInstance {
    nome: string;
    username: string;
    password: string;
    onde_conheceu: string;
}

export class CadastroInvestidorForm extends ModelForm {
    instance!: ICadastroInvestidor;
}

export interface ICadastroInvestidorPJ extends IFormInstance {
    cnpj: number;
    nome_fantasia: string;
    email: string;
    telefone_celular: string;
    password: string;
}

export class CadastroInvestidorPJForm extends ModelForm {
    instance!: ICadastroInvestidorPJ;
}

export interface ICadastroIncorporadora extends IFormInstance {
    cnpj: number;
    nome_fantasia: string;
    email: string;
    telefone: string;
    password: string;
}

export class CadastroIncorporadoraForm extends ModelForm {
    instance!: ICadastroIncorporadora;
}

export interface IRedefinirSenhaHome extends IFormInstance {
    email: string;
}

export class RedefinirSenhaHomeForm extends ModelForm {
    instance!: IRedefinirSenhaHome;
}