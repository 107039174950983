<script>
export default {
    name: "Card",
    props: {
        icon: {type: String, default: null},
        primary: Boolean,
        colSpan: {type: Number, default: 1},
        title: String,
        body: String | Number,
        bodyBold: Boolean,
        titleClass: {type: String, default: 'font-small'},
        bodyClass: {type: String, default: ''},
        wrapperClass: {type: String, default: ''},
        hintFunction: {type: Function, default: null},
        hintText: {type: String, default: null},
        hintIcon: {type: String, default: null}
    }
}
</script>

<template>
    <div v-if="!icon" class='card flex items-center border-main p-small h-[8rem]' :class="`col-span-${colSpan} ${wrapperClass}`">
        <div>
            <div class='card-header flex gap-4 items-center' :class="titleClass">
            {{title}}
            <template v-if="hintIcon">
                <div v-if="hintText" class="relative hover-wrapper" >
                    <img :src="hintIcon" alt="" class="pointer filter-white">
                    <p class="hover-info card-hint">{{ hintText }}</p>
                </div>
            </template>
        </div>
        <div class='font-main whitespace-nowrap' :class="bodyBold ? 'font-bold' : ''">
            <p :class="`${body !=0 && !body ? 'skeleton skeleton-text mt-2' : ''} ${bodyClass}`">
                {{body}}
            </p>
        </div>
        </div>
    </div> 

    <div v-else class='card flex p-small items-center' :class="`col-span-${colSpan} ${wrapperClass}`">
        <div className="status-wrapper flex gap-2">
            <img :src="icon"/>
            <p class="font-small"><strong>{{title}}</strong></p>
            <template v-if="hintIcon">
                <img v-if="hintFunction" @click="hintFunction()" :src="hintIcon" alt="" class="pointer">
                <div v-if="hintText" class="relative hover-wrapper" >
                    <img :src="hintIcon" alt="" class="pointer filter-white">
                    <p class="hover-info calendario dark-blue white-semi-bg">{{ hintText }}</p>
                </div>
            </template>
        </div>
    </div>  
</template>
