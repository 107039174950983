<script lang="ts">
import { PropType, defineComponent } from 'vue';
import ModalBase from './ModalBase.vue';
import SubmitButton from './SubmitButton.vue';

export default defineComponent({
    name: "ModalEnvioMensagem",
    props: {
        close: {type: Function as PropType<()=>void>, default: ()=>{}},
        order: {type: Number, default: 0},
        width: { type: Number, default: 50 },
        title: {type: String, default: ''},
        subTitle: {type: String, default: ''},
        callback: {type: Function as PropType<(texto_inicial: string)=>void>, default: (texto_inicial: string)=>{}},

    },
    components: {
        ModalBase,
        SubmitButton
    },
    methods: {
        enviarProposta() {
            const texto_inicial = this.$refs.text.value
            this.callback(texto_inicial)
            this.close()
        }
    }
    
})
</script>

<template>
    <ModalBase @close="close" :order="order" :width="width" classes="bg-white border-main dark-blue-bg flex justify-center items-center relative">
        <div @click="close" class="text-white absolute top-4 right-5 cursor-pointer">
            <i class="fal fa-times font-bold"></i>
        </div>
        <div class="px-[2rem]">
            <h2 class="text-[2rem] font-medium text-center text-white">{{ title }}</h2>
            <p v-if="subTitle" class="text-[1.4rem] text-white text-center">{{ subTitle }}</p>
            <div class="mt-[2rem]">
                <textarea ref="text" class="dark-blue rounded-xl w-full h-[14rem] text-[1.4rem] resize-none"></textarea>
            </div>
            <div class="">
                <SubmitButton @click="enviarProposta" text="Enviar" :no-border="true" :no-padding="true" :no-block="true" classes="white-bg rounded-xl w-full mt-[1rem]" />
            </div>
        </div>
    </ModalBase>
</template>