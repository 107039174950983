import { Socio } from './Socio';
import {CaptacaoInadimplente} from './Captacao';

export interface UF {
    id: number,
    sigla: string,
    nome: string
}

export interface Municipio {
    id: number,
    nome: string,
}

export interface Banco {
    id: number,
    codigo: string,
    nome: string,
    status: boolean;
}

export interface SPE {
    id: number,
    razao_social: string,
    projetos: string | string[],
    cnpj: string,
    conta_bancaria: string,
}

export interface TipoSociedade {
    code: number,
    name: string,
}

export type Constants = 'tipos_sociedade'
                        | 'generos'
                        | 'nacionalidades'
                        | 'estados_civis'
                        | 'categorias'
                        | 'modalidades_projeto'
                        | 'usos_projeto'
                        | 'layouts_projeto'
                        | 'publicos_alvo'
                        | 'destinacoes_recursos'
                        | 'tipos_garantia'
                        | 'prazo_para_desembolso'
                        | 'periodo_contabil'
                        | 'estados_civis_socios'
                        | 'tipos_documentos'
                        | 'tipos_conta_bancaria';


export interface CountdownInterface {
    value: string;
}

export class Processing {
    private value: boolean;
  
    constructor() {
      this.value = false;
    }
  
    getValue(): boolean {
      return this.value;
    }
  
    setValue(newValue: boolean): void {
      this.value = newValue;
    }
    
    isBusyOrSetBusy(): boolean {
        if(!this.getValue()) {
            this.setValue(true);
            return false;
        }
        return true;
    }

    reset(): void {
        this.setValue(!this.getValue())
    }
}

export class Menu {
    visible: boolean
    subMenu: Menu | null
    
    constructor(visible = false, subMenu = null) {
        this.visible = visible;
        this.subMenu = subMenu;
    }

    closeMenu() {
        this.visible = false;
        if (this.subMenu instanceof Menu) {
            this.subMenu.closeMenu();
        }
    }

    openMenu() {
        this.visible = true;
    }

    isVisible() { 
        return this.visible
    }
}

export interface DadosPaginaInvestidor {
    anos_unicos : Array<any>;
    captacoes_com_atraso: Array<any>;
    captacoes_em_dia: Array<any>;
 
    captacoes_inadimplentes: Array<any>;
    captacoes_inadimplentes_tabela: Array<CaptacaoInadimplente>;
    captacoes_perda_de_capital: Array<any>;

    dados_grafico_rentabilidade_minima: Array<any>;
    dados_grafico_rentabilidade_minima_media: Array<any>;
    dados_grafico_rentabilidade_realizada: Array<any>;
    dados_grafico_rentabilidade_realizada_media: Array<any>;
    informacoes_mapa: Array<any>;
 
    numero_projetos: number;
    percentual_com_atraso: number;
    percentual_em_dia: number;
    percentual_inadimplentes: number;
    percentual_perda_de_capital: number;

    quantidades_captacoes_com_atraso: Array<any>;
    quantidades_captacoes_em_dia: Array<any>;

    quantidades_captacoes_inadimplentes: Array<any>;
    quantidades_captacoes_perda_de_capital: Array<any>;

    regra_dias_inadimplencia: number;
    rentabilidade_media_em_andamento: number;
    rentabilidade_media_finalizado: number;
    rentabilidade_media_total: number;

    total_bruto_estimado: string;
    total_estimado_a_receber: string;
    total_captado: string;
    total_captado_em_andamento: string;
    total_captado_finalizado: string;
    total_retornado: string;
}
