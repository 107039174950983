<script lang="ts">
import { PropType, defineComponent } from 'vue';
import ModalBase from './ModalBase.vue';
import copyPaste from '@/assets/copy.svg';
import {CountdownInterface} from '@/Types/General';
import { startCountdown, copyToClipboard } from "@/services/Browser";
import { isMobile } from '@/services/Mobile';


export default defineComponent({
    name: "ModalPix",
    props: {
        close: {type: Function as PropType<()=>void>, default: ()=>{}},
        order: {type: Number, default: 0},
        pix: {type: Object, required: true},
        qrcodeSrc: {type:String, required:true},
        title: {type: String, required: false},
    },
    data(){

        return {
            startCountdown,
            copyToClipboard,
            mobile: isMobile(),
            countdown : {value : ''} as CountdownInterface,
            copyPaste
        }
    },
    components: {
        ModalBase, 
    },
    mounted() {
        if(this.pix.vencimento) {
            this.startCountdown(this.pix.vencimento, this.countdown, 'Expirado')
        }
    },
})
</script>

<template>
    <ModalBase @close="close" :order="order" :width="70" classes="dark-mode border-main flex justify-center items-center relative p-4">
        <div @click="close" class="absolute top-4 right-5 cursor-pointer">
            <i class="fal fa-times"></i>
        </div>
            <div v-if="pix" class="content p-main w-full">

                    <h5 v-if="title" class="text-[2.3rem] text-white text-center mb-[2rem]"> {{ title }}</h5>
    
                    <div class="flex justify-center mb-main">
                        <img :src="qrcodeSrc" alt="" class="w-[20rem] h-[20rem]">
                    </div>
                    <div class="flex justify-center items-center cursor-pointer font-main font-bold mb-main gap-2" @click="copyToClipboard(pix.emv)">
                            ou copie e cole
                            <img :src="copyPaste" class="filter-white w-[2rem] h-[2rem]">    
                    </div>
                    <div class="lg:w-[60rem] h-[20rem] lg:h-[10rem] break-words overflow-auto mb-main">
                        
                        {{ pix.emv }}
                    </div>
    
                <div class="text-center font-bold color-error tabular-nums">
                    {{ countdown.value }}
                </div>
            </div>
    </ModalBase>
</template>