<script lang="ts">
import warningIcon from '@/assets/warning.svg';
import checkIcon from '@/assets/check.svg';
import Loading from './Loading.vue';
import { string } from 'prop-types';

export default {
    name:"SubmitButton",
    props: {
        disabled: {type: Boolean, default: false},
        error: {type: Boolean, default: false},
        noBorder: {type: Boolean, default: false},
        text:{type: String, default: "Salvar"},
        noError: {type: Boolean, defalt: false},
        onClickAction :{type: Function, default: null},
        noBlock: {type: Boolean, default: false},
        blocked: {type: Boolean, default: false},
        blockedMessage: {type: String, default: "Não é possível editar o formulário"},
        noPadding: {type: Boolean, defaut: false},
        classes: {type: String, default: 'dark-blue-bg text-white border-main'},
        fontSize: {type: String, default: 'text-2xl'},
    },
    components: {
        Loading,
    },
    data() {
        return {
            warningIcon,
            checkIcon
        }
    }
}
</script>

<template>
    <div class="flex gap-main" :class="{'border-section-top': !noBorder, 'py-main': !noPadding}">
        <div v-if="!blocked && !noBlock" class="col-span-3 flex-1 flex items-center">
            <slot/>
        </div>
        <button v-if="onClickAction" @click="onClickAction" :disabled="disabled" :class="classes + ' ' + fontSize"  class="submit-button p-2 w-44 col-span-1 items-center justify-center hover:font-medium">
            {{ text }}
        </button>
        <div v-else-if="blocked" :class="classes + ' ' + fontSize" class="w-full p-4 whitespace-nowrap text-center border-success">
            {{ blockedMessage }}
        </div>
        <button v-else :disabled="disabled" :class="classes + ' ' + fontSize" class="submit-button p-2 px-12 w-fit min-h-[3.6rem] col-span-1 items-center justify-center">
            <p class="salvar-text">{{ text }}</p>
            <Loading class="save-loading hidden" :size="2.5" />
        </button>
    </div>
</template>