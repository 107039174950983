<script lang="ts">
import { PropType, defineComponent } from 'vue';
import ModalBase from './ModalBase.vue';

export default defineComponent({
    name: "ModalConfirmar",
    props: {
        close: {type: Function as PropType<()=>void>, default: ()=>{}},
        order: {type: Number, default: 0},
        message: {type: String as PropType<string | string[]>, default: "Você tem certeza?"},
        callback: {type: Function as PropType<(arg?:any)=>void>, default: (any)=>{}},
        arg: {default: ''}
    },
    components: {
        ModalBase
    }
})
</script>

<template>
    <ModalBase @close="close" :order="100" classes="bg-white border-main dark-blue-bg flex justify-center items-center relative">
        <div @click="close" class="text-white absolute top-4 right-5 cursor-pointer">
            <i class="fal fa-times"></i>
        </div>
        <div class="content p-main">
            <div>
                <img>
            </div>
            <div class="text-center font-large">
                <div class="text-white mb-main" v-html="message"></div>
                <div class="flex gap-main">
                    <button @click="()=>{close();callback(arg)}" class="font-main p-2 w-full self-end bg-white border-main col-span-2 h-fit">
                        Confirmar
                    </button>
                    <button @click="close" class="font-main p-2 w-full self-end bg-white border-main col-span-2 h-fit">
                        Cancelar
                    </button>
                </div>
            </div>
        </div>
    </ModalBase>
</template>