export enum InputType {
    FILE,
    TEXT,
    TEXT_SEARCH,
    TEXTAREA,
    SELECT,
    FILTER_SELECT,
    CHECKBOX,
    RANGE,
    RANGE_MIN_MAX,
    PASSWORD,
    UPDATE_NUMBER,
    RADIO,
    TOGGLE,
    TOKEN,
    AUTOCOMPLETE,
}

export enum FileType {
    ANY = "",
    IMAGE = "image/jpeg, image/png, image/jpg",
    ONLY_PNG = 'image/png',
    PDF = "application/pdf",
    ID_DOCUMENT = "image/jpeg, image/png, image/jpg, application/pdf",
    EXCEL = ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
}

export interface Option {
    code: string | number | boolean,
    name: string
}

export const FILE_MAX_SIZE = 20_971_520;
export const IMAGE_MAX_SIZE = 30_000_000;
export const REQUEST_MAX_SIZE = 32_000_000;