<script lang="ts">
import { PropType, defineComponent } from 'vue';
import ModalBase from './ModalBase.vue';
import downloadIcon from '@/assets/download.svg';
import fileDownloadIcon from '@/assets/file-download.svg'


export default defineComponent({
    name: "ModalArquivos",
    props: {
        close: {type: Function as PropType<()=>void>, default: ()=>{}},
        order: {type: Number, default: 0},
        arquivos: {type: Array<any>, required:true},
        linkTodosArquivos:{type: String, required:true}
    },
    data(){
        return {
            downloadIcon,
            fileDownloadIcon
        }
    },
    components: {
        ModalBase
    }
})
</script>

<template>
    <ModalBase @close="close" :order="order" :width="50" classes="dark-blue-bg border-main flex justify-center items-center relative">
        <div @click="close" class="text-white absolute top-4 right-5 cursor-pointer">
            <i class="fal fa-times"></i>
        </div>
        <div class="content p-main">
            <div class="text-center">
                <div v-if="arquivos.length > 0">
                    <div class="font-large text-white font-bold">Meus arquivos</div>
                    <div class="font-large text-white mb-main hover:underline">
                        <a download :href="linkTodosArquivos" class="flex items-center">
                            Baixar todos os arquivos <img :src="downloadIcon" class='h-10 w-10 ml-[.5rem]'>
                        </a>
                    </div>
                    <div v-for="arquivo in arquivos" class="text-white mb-main">
                        <a download :href="arquivo.url" target="_blank" class="hover:underline flex">
                            {{ arquivo.nome }} <img :src="fileDownloadIcon" class='rounded-lg filter-white ml-[.5rem]'>
                        </a>    
                    </div>
                </div>
                <div v-else class="text-white font-large">
                    <p>Você ainda não possui nenhum arquivo<br>disponível para baixar.</p>
                </div>

            </div>
        </div>
    </ModalBase>
</template>