<script lang="ts">
import { PropType, defineComponent } from 'vue';
import ModalBase from './ModalBase.vue';

export default defineComponent({
    name: "ModalConfirmar",
    props: {
        close: {type: Function as PropType<()=>void>, default: ()=>{}},
        order: {type: Number, default: 0},
        callBack: {type: Function, default: () => {}},
        message: {type: String, required: true},
        title: {type: String, required: true}
    },
    components: {
        ModalBase
    },
    methods: {
        closeModal() {
            this.callBack();
            this.close();
        }
    }
})
</script>

<template>
    <ModalBase @close="closeModal" :order="order" classes="bg-white border-main dark-blue-bg flex justify-center items-center relative">
        <div @click="closeModal" class="text-white absolute top-4 right-5 cursor-pointer">
            <i class="fal fa-times"></i>
        </div>
        <div class="content p-main">
            <div>
                <img>
            </div>
            <div class="text-center text-white font-large">
                <div class="font-bold">{{title}}</div>
                <div>{{message}}</div>
            </div>
        </div>
    </ModalBase>
</template>