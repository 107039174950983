export enum MaskType {
    CPF,
    CNPJ,
    NUMERO,
    PERCENTUAL,
    TELEFONE,
    DECIMAL,
    DINHEIRO,
    NUMERO_NEGATIVO,
    DATA,
    DATA_MIN,
    CEP,
    TEXTLIMIT,
}