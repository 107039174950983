<script lang="ts">
import { PropType, defineComponent } from 'vue';
import ModalBase from './ModalBase.vue';
import { colors } from '@/styles/styles'

export default defineComponent({
    name: "ModalError",
    props: {
        close: {type: Function as PropType<()=>void>, default: undefined},
        order: {type: Number, default: 0},
        messages: {type: [String, Array] as PropType<string | string[]>, required: false}
    },
    data() {
        return {
            colors
        }
    },
    methods: {
        closeModal() {
            this.close()
            this.$destroy();
        }
    },
    components: {
        ModalBase
    }
})
</script>

<template>
    <ModalBase @close="close" :order="order" classes="bg-white border-main dark-blue-bg flex justify-center items-center relative">
        <div @click="close" class="text-white absolute top-4 right-5 cursor-pointer">
            <i class="fal fa-times"></i>
        </div>
        <div class="content p-main">
            <div>
                <img>
            </div>
            <div class="text-center font-large text-white">
                <template v-if="messages">
                    <p v-if="Array.isArray(messages)"
                        v-for="message, index in messages"
                        :class="index==0 ? 'font-big font-bold mb-4' : 'font-main'"
                        :style="{color: index==0 ? 'white' : colors.lightBlue}">
                        {{message}}
                    </p>
                    <p v-else>{{ messages }}</p>
                </template>
                <template v-else>
                    <strong>Ocorreu um erro inesperado!</strong><br/>
                    Por favor, tente novamente mais tarde.
                </template>
            </div>
        </div>
    </ModalBase>
</template>