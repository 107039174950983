<script lang='ts'>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Loading',
    props: {
        isOn: {type: Boolean, default: true},
        size: {type: Number, default: 4}
    }
});

</script>

<template>
    <div class="w-full h-full flex justify-center items-center flex-1">
        <div class="loading" :style="{'--size': size+'rem'}">
        </div>
    </div>
</template>

<style scoped>
/* Safari */

.loading::after {
    content:'';
    display:block;
    border: 4px solid white;
    border-radius: 50%;
    border-top: 4px solid #001A42;
    width: var(--size);
    height: var(--size);
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;

}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

</style>