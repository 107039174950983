import axios from "axios";
import { Banco, Constants, Municipio, TipoSociedade, UF } from "../../Types/General";
import GeneralGateway from "./generalGateway";
import { BASE_URL } from "../../env/variables";
import api from '../../services/Api'

export default class GeneralGatewayHttps implements GeneralGateway {
    async getConstants(name: Constants | string = ''): Promise<any> {
        const response = await api.get<any>(`${BASE_URL}data/constants/${name}`)
        return response
    }

    async getMunicipios(estado: number): Promise<Municipio[]> {
        const response = await axios.get<Municipio[]>(`${BASE_URL}data/municipios/${estado}`);
        const municipios = response.data;
        return municipios
    }
    async getBancos(): Promise<Banco[]> {
        const response = await axios.get<Banco[]>(`${BASE_URL}data/bancos`);
        const bancos = response.data;
        return bancos;
    }
    async getUnidadesFederativas(): Promise<UF[]> {
        const response = await axios.get<UF[]>(`${BASE_URL}data/unidades-da-federacao`);
        const unidadesFederativas = response.data;
        return unidadesFederativas;
    }

    async getCEPData(cep: string): Promise<any> {
        const response = await axios.get<any>(`https://viacep.com.br/ws/${cep.replace(/\D/g, '')}/json/`);
        return response.data;
    }

    async getCaptacoesAbertas(): Promise<any> {
        const response = await axios.get<any>(`${BASE_URL}data/captacoes-abertas`);
        return response.data;
    }

    async getDadosContadorHome(): Promise<any> {
        const response = await axios.get<any>(`${BASE_URL}data/contador-home`);
        return response.data;
    }

    async getUserAccess(): Promise<any> {
        const response = await axios.get<any>(`${BASE_URL}get-auth`);
        return response.data
    }

    async getDadosPaginaIncorporador(): Promise<any> {
        const response = await axios.get<any>(`${BASE_URL}data/incorporador`);
        return response.data;
    }
    
    async getDadosPaginaAjuda(): Promise<any> {
        const response = await api.get<any>(`${BASE_URL}data/ajuda`)
        return response;
    }

    async getDadosPaginaProjeto(incorporadoraSlug: string, projetoSlug: string): Promise<any> {
        const response = await api.get<any>(`${BASE_URL}data/projeto-detail/${incorporadoraSlug}/${projetoSlug}`)
        return response;
    }

    async getDadosPaginaInvestidor(): Promise<any> {
        const response = await api.get<any>(`${BASE_URL}data/investidor/`)
        return response;
    }
    
    async getCNPJData(cnpj: string): Promise<any> {
        const response = await api.get<any>(`${BASE_URL}data/dados-cnpj/${cnpj}`);
        return response;
    }

    async postCalculadora(body: FormData, params: {tipo: string, step: number}): Promise<any> {
        const response = await api.post<any>(body, `${BASE_URL}calculadora/${params.tipo}/enviar/${params.step}/`);
        return response;
    }

    async postCalculadoraTrocarTipo(): Promise<any> {
        const response = await api.post<any>({}, `${BASE_URL}calculadora/trocar-tipo/`);
        return response;
    }

    async getHashRatingCalculadora(): Promise<any> {
        const response = await api.get<any>(`${BASE_URL}calculadora/gerar-rating/`);
        return response
    }

    async getDadosCalculadora(hashRating: string): Promise<any> {
        const response = await api.get<any>(`${BASE_URL}data/calculadora/rating/${hashRating}`);
        return response;
    }
}