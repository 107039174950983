export const easeInOutQuad = (currentTime: any, startValue : any, changeValue : any, duration: any) => {
    currentTime /= duration/2;
    if (currentTime < 1) return changeValue/2*currentTime*currentTime + startValue;
    currentTime--;
    return -changeValue/2 * (currentTime*(currentTime-2) - 1) + startValue;
}

export function jurosAnuaisParaMensais(taxaAnual: number) {
    // Converte uma taxa de juros anual para uma taxa de juros mensal
    let taxaMensal = Math.pow(1 + taxaAnual, 1 / 12) - 1;
    return taxaMensal;
}