import InvestidorGateway from '@/infra/gateway/investidorGateway';
import api from './Api';
import modalService from './ModalService';
import {CountdownInterface, Processing} from '@/Types/General'
import { Pix } from '@/Types/Investimento';

export const getParams = (param: string) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get(param);
}

export const clearParams = (param: string) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    urlParams.delete(param)
    window.history.replaceState(null, '', `${window.location.pathname}?${urlParams.toString()}`);
}

export const downloadFileFromUrl = async (url: string, fallbackName: string = '') => {
    const response = await api.get<any>(url, 'blob', true);
    return new Promise(resolve => {
        if(!response) {
            modalService.createError("Não foi possível baixar o arquivo");
            return resolve('error')
        } 
        downloadFile(response, fallbackName);
        return resolve('ok')
    })
}

export const redirect = (url: string) => {
    if (url.includes("www"))
        url = window.location.origin + url
    window.location.replace(url)
}

export const getCookie = (name: string) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()!.split(';').shift();
}

export const downloadFile = (response: any, fallbackName="file.xlsx") => {
    if(!response) return;
    let defaultName: string = '';
    
    if(!response.headers && !('content-disposition' in response?.headers)) {
        modalService.createError();
        return
    }

    try {
        const disposition: string[] = response.headers['content-disposition'].match(/filename=(.+)/)
        defaultName = disposition[1];
    } catch {
        defaultName = fallbackName;
    }

    var file = document.createElement('a');
    const url = window.URL.createObjectURL(new Blob([response.data]));
    file.href = url;
    file.download = defaultName;
    file.click();
    file.remove();
}

export const goToUrl = (url: string | undefined, linkType: string = '_blank') => {
    window.open(url, linkType);
}

export const getOffset = (el: HTMLElement) => {
    const rect = el.getBoundingClientRect();
    return {
        left: rect.left + window.scrollX,
        top: rect.top + window.scrollY
    };
}

export const  startCountdown = async (dataHora : string | null, countdown: CountdownInterface, message: string ) => {
    if(!dataHora){
        countdown.value = "";
        return;
    }
    let countDownDateTime = new Date(dataHora).getTime();

    let x = setInterval(()=> {

    let now = new Date().getTime();

    let distance = countDownDateTime - now;

    let days = Math.floor(distance / (1000 * 60 * 60 * 24));
    let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((distance % (1000 * 60)) / 1000);
    
    const dayString = days > 0 ? `${days}d ` : "";   
    const hourString = hours > 0  ? `${hours}h ` :  days > 0 ? `${hours}h ` : "";
    const minuteString = minutes > 0 ? `${minutes}m ` : hours > 0 ? `${minutes}m `:  "";
    const secondString = seconds > 0  ? `${seconds}s` : minutes > 0 ? `${seconds}m `:  "";

    countdown.value = `${dayString}${hourString}${minuteString}${secondString} restantes`
    if (distance < 0) {
        clearInterval(x);
        countdown.value = message;
    }
    }, 1000);
}


export const copyToClipboard = async (text: string) => {
    try {
        await navigator.clipboard.writeText(text);
            alert('QR Code Copiado!');
        } catch($e) {
            alert('Erro ao copiar, tente selecionar o texto e copiar manualmente!');
     }
}

export const sleep = (delay: number) => new Promise((resolve) => setTimeout(resolve, delay))

export const getPixEstatico = async (gateway: InvestidorGateway, processing: Processing, pix: Pix, callBack: Function) =>  {
    if(processing.isBusyOrSetBusy()) return;
    if(!pix.emv || !pix.qrcode){
        const response = await gateway.postGerarPixEstatico();
        if(response.success){
            pix.emv = response.data.emv;
            pix.qrcode = response.data.qrcode;
            pix.vencimento = response.data.vencimento;

            callBack();
            return;
        }
        processing.setValue(false);
        return;
    } 
    callBack();   
}

export const tiposArquivos = [
    {
        "code": "ccb",
        "name": "CCB"
    },
    {
        "code": "cci",
        "name": "CCI"
    },
    {
        "code": "cri",
        "name": "CRI"
    },
    {
        "code": "ntc",
        "name": "Nota Comercial"
    },
    {
        "code": "ots",
        "name": "Outros"
    }
]

