<script lang="ts">
import { PropType, defineAsyncComponent, defineComponent } from 'vue';
import ModalBase from './ModalBase.vue';
import { InputType } from '@/Types/InputTypes';
import { sleep } from '@/services/Browser';
import { Processing } from '@/Types/General'
import ModalService from '@/services/ModalService';
import SubmitButton from '@/Components/SubmitButton.vue';
import SenhaCheckList from '@/Components/SenhaCheckList.vue';

const InputField = defineAsyncComponent(() =>
  import('@/Components/InputField.vue')
)

export default defineComponent({
    name: "ModalSenha",
    props: {
        close: {type: Function as PropType<()=>void>, default: ()=>{}},
        order: {type: Number, default: 0},
        callback: {type: Function as PropType<(e:any, i:any)=>void>, default: () => {}},
        redefinirSenhaDashboard: {type: Boolean, default: true},
    },
    data(){


        return {
            sleep,
            InputType,
            processing: new Processing(),
            checks: new Array(5).fill('') as Array<string>,
            validPassword: false,

            passwordObject: {
                new_password1: "",
                new_password2: "" 
            }
        }
    },
    methods : {
        async verifyPassword(){
            const pattern = [{test: (str:string) => str.length >= 8}, new RegExp("^(?=.*[A-Z])"), new RegExp("^(?=.*[-+_!@#$%^&*., ?])"), new RegExp("^(?=.*\\d)")]
            pattern.forEach((v, i)=>{
                if(v.test(this.passwordObject.new_password1.trim())) {
                    this.checks[i] = `password-${i+1}`
                } else {
                    this.checks[i] = ''
                }
            })
            if(this.passwordObject.new_password1 == this.passwordObject.new_password2 && this.passwordObject.new_password1.trim() != ""){
                this.checks[4] = `password-5`
            } else{
                this.checks[4] = ''
            }

            this.validPassword = this.checks.every(element => element)

        },
        async submitPassword(event: any) {
            if (!this.validPassword) return
            this.processing.setValue(true)
            this.callback(event, this.passwordObject);
            await this.sleep(500)
            if(!this.redefinirSenhaDashboard){
                this.close();
                ModalService.mensagemComTitulo('Entre na sua conta com sua nova senha!', 'Senha redefinida!')
                return;
            }
            setTimeout(() => {
                window.location.replace('/dashboard/')
            }, 3500);
            close();
        }
    },
    components: {
        ModalBase, InputField, SubmitButton, SenhaCheckList 
    },
    mounted() {
    },
})
</script>

<template>
    <ModalBase @close="close" :order="order" classes="bg-white border-main flex justify-center items-center relative p-4">
        <div @click="close" class="dark-blue absolute top-4 right-5 cursor-pointer">
            <i class="fal fa-times"></i>
        </div>
        <div class="content p-main !py-14">
            <div>
                <h1 class="font-xl flex justify-center">Redefinir Senha</h1>
                <h1 class="flex justify-center">Informe sua nova senha abaixo</h1>
            </div>
            <div class="text-center">
                <form action="POST" enctype='multipart/form-data' class="mb-main" @submit.prevent="(event)=>{submitPassword(event)}">
                    <div class="mt-8">
                        <InputField
                            name="new_password1"
                            label="Nova senha"
                            label-classes="pt-[0rem] grey-label font-small"   
                            v-model:value="passwordObject.new_password1"
                            :type="InputType.PASSWORD"
                            @verifyPassword="verifyPassword"
                            placeholder=""
                            :isRequired="true"
                        />
                    </div>
                    <div class="mt-8">
                        <InputField
                            name="new_password2"
                            label-classes="pt-[0rem] grey-label font-small"
                            label="Confirmação da nova senha"
                            v-model:value="passwordObject.new_password2"
                            @verifyPassword="verifyPassword"
                            :type="InputType.PASSWORD"
                            placeholder=""
                            :isRequired="true"
                        />
                    </div>

                        
                    <SubmitButton 
                        :disabled="!validPassword || processing.getValue()" 
                        text="Redefinir Senha" 
                        :noBorder="true" 
                        :noBlock="true" 
                        :no-padding="true"
                        class="whitespace-nowrap px-4 mt-10 justify-center" />
                </form>
                <SenhaCheckList :checks="checks" :senhas-iguais="true" />

            </div>
        </div>
    </ModalBase>
</template>
