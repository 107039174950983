<script lang="ts">
import { PropType, defineComponent } from 'vue';
import BaseItem from '@/Components/BaseItem.vue';
import ActionButton from './ActionButton.vue';
import { DateParse, Money, Percent } from '@/utils/formats';

import arrowUp from '@/assets/order_up.svg';
import arrowDown from '@/assets/order_down.svg';

interface Attribute {
    name: string | string[],
    type?: 'number' | 'string' | 'date',
    filter?: boolean,
    total?: { type: 'number' | 'numberNoDecimal' | 'percent' | 'money' | 'count' | 'count_periods'| 'value' , compare_value?:string, value?:string, filter?: {field: string, value: any}},
}

interface Label {
    size: number,
    attribute?: Attribute,
    hint?: boolean,
    hintName?: string,
    additionals?: Object,
    hide?: string
}

export default defineComponent({
    name: "BaseTable",
    props: {
        labels: {type: Object as PropType<Array<String> | Record<string, Label>>, required: true},
        maxHeight: {type: Number, default: undefined},
        noSetHeight: {type: Boolean, default: false},
        objects: {type: Object as PropType<Object[]>, default: undefined},
        gapSmall: {type: Boolean, default: false},
        additionalClass: {type: String, default: ''},
        title: {type: String, default: undefined},
        isMobile: {type: Boolean, default: false},
        overflowHidden: {type: Boolean, default: false},
        overflowAuto: {type: Boolean, default: true},
        headerMargin: {type: String, default: 'mb-main'},
        colsClass:{type: String, default: ''},
    },
    components: {
        BaseItem,
        ActionButton
    },
    data() {
        const filteredItems = this.objects;
        this.emitFilter(filteredItems)
        let columns = 0;
        let hasTotal = false;
        if(!Array.isArray(this.labels)) {
            columns = Object.entries(this.labels).map(([key, value]) => { return value }).reduce((sum, value)=> sum+value.size, 0);

            for (let label in (this.labels as Record<string, Label>)) {
                if ((this.labels as Record<string, Label>)[label].attribute?.total) {
                    hasTotal = true;
                    break;
                }
            }
        }

        let hasAdditionals = false;
        for (let [label, options] of Object.entries(this.labels)) {
            if(options.additionals) hasAdditionals = true
        }

        return {
            currentOrder: undefined as {attribute: string, ascending: boolean} | undefined,
            currentFilters: {},
            arrowUp,
            arrowDown,
            totais: {},
            columns,
            hasTotal,
            hasAdditionals
        }
    },
    methods: {
        hasFilters() {
            for (let key in this.currentFilters) {
                return true;
            }
            return false;
        },
        filter(key: string, value: string) {
            if (key) {
                if (!value || value == '')
                    delete this.currentFilters[key]
                else 
                    this.currentFilters[key] = value.toLowerCase();
            }

            let filteredElements = this.objects;
            for(let filter in this.currentFilters) {
                filteredElements = filteredElements.filter(element => { return String(element[filter]).toLowerCase().includes(this.currentFilters[filter]) })
            }
            this.emitFilter(filteredElements);
        },
        emitFilter(incorporadoras: Object[] | undefined) {
            this.$emit('filter', incorporadoras);
        },
        openMenu(id) {
            this.$emit('openMenu', id);
        },
        compare(left: any, right: any, ascending: boolean) {
            if (ascending) {
                if(left > right) return -1;
                if(left < right) return 1;
                return 0;
            } else {
                if(left < right) return -1;
                if(left > right) return 1;
                return 0;
            }
        },
        joinAttribute(attribute: string | string[]) {
            return Array.isArray(attribute) ? attribute.join('_') : attribute;
        },
        calculateTotal(type: any, label: any){
            let objects;
            const filter = type.attribute.total.filter;
            if (filter) {
                objects = this.objects.filter(object => object[filter.field as keyof Object] == filter.value)
            } else {
                objects = this.objects
            }

            switch(type.attribute.total.type) {
                case 'money': {
                    this.totais[label] = Money(objects.reduce((sum: number, element: Object) => {return sum + this.getAttribute(element, type.attribute?.name)}, 0));
                    break;
                }
                case 'count': {
                    this.totais[label] = objects.reduce((sum:number) => {return sum + 1}, 0);
                    break;
                }
                case 'count_periods': {
                    // TODO - contar da primeira data à última
                    const dateTo = new Date(objects.slice(-1)[0][type.attribute?.name] + 'T00:00:00.000')
                    const dateFrom = new Date(objects[0][type.attribute?.name] + 'T00:00:00.000')
                    const periods = dateTo.getMonth() - dateFrom.getMonth() + (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
                    this.totais[label] = String(periods + ' meses');
                    break;
                }
                case 'percent': {
                    const percent = objects.reduce((sum:number, element:Object) => {
                        return sum + (this.getAttribute(element, type.attribute?.name))
                    }, 0);
                    this.totais[label] = Percent(percent, false);
                    break;
                }
                case 'number': {
                    const number = objects.reduce((sum:number, element:Object) => {return sum + this.getAttribute(element, type.attribute?.name)}, 0);
                    this.totais[label] = Money(number, false);
                    break;
                }
                case 'numberNoDecimal': {
                    const number = objects.reduce((sum: number, element: Object) => sum + this.getAttribute(element, type.attribute?.name), 0);
                    const numberFormatado = Money(number, false);
                    const numberString = String(numberFormatado).split(',')[0]; 
                    this.totais[label] = numberString;
                    break;
                }
                case 'value': {
                    this.totais[label] = type.attribute.total.value;
                    break;
                }
            }
        }
        ,
        getTotais() {
            if(!this.objects)
                return

            for (let label in this.labels) {
                const type = this.labels[label] as Label

                if(type.additionals) {
                    for(let add in type.additionals){
                        const additional = type.additionals[add]
                        if(additional.attribute.total) {
                            this.calculateTotal(additional,`${add}-${label}`)
                        }
                    }
                }
                else{
                    if(type.attribute?.total) {
                        this.calculateTotal(type,label)
                    }
                }
            }
        },
        getAttribute(object: Object, attribute: string | string[]) {
            let currentValue;
            if (Array.isArray(attribute)){
                currentValue = object[attribute[0]];
                for (let index = 1; index < attribute.length; index++) {
                    currentValue = currentValue[attribute[index]];
                }

            } else {
                currentValue = object[attribute];
            }
            return currentValue;
        },
        convertToType(type: 'string' | 'number' | 'date', value: string | number) {
            switch(type) {
                case 'string': return String(value);
                case 'number': {
                    if (value == null || value == undefined || value == "" || value == "-") return 0
                    return Number(value)
                };
                case 'date': {
                    if (value == null || value == undefined || value == "" || value == "-") return 0
                    return Number(DateParse(value as string)) 
                };
                default: return null;
            }
        },
        order_by(options: Label) {
            if (options.attribute){
                let order = false;
                const attribute_name = this.joinAttribute(options.attribute.name);
                if (!this.currentOrder || this.currentOrder.attribute != attribute_name) {
                    this.currentOrder = {
                        attribute: attribute_name,
                        ascending: false
                    }
                } 
                else
                {
                    this.currentOrder.ascending = order = !this.currentOrder.ascending;
                }

                const attribute = options.attribute.name
                const type = options.attribute.type ? options.attribute.type : 'number';
                this.objects.sort((a: Object, b: Object) => {
                    const left = this.convertToType(type, this.getAttribute(a, attribute))
                    const right = this.convertToType(type, this.getAttribute(b, attribute))
                    return this.compare(left, right, order)
                })

                if (this.hasFilters())
                    this.filter();
            }
        }
    },
    watch: {
        objects: function() {
            this.filter();
            this.getTotais();
        }
    },
    mounted() {
        this.getTotais()
    }
})
</script>
 
<template>
    <div id='main-table' :class="additionalClass">
        <template v-if="!isMobile">
            <h1 class="font-medium text-center">{{title}}</h1>
            <template v-if="Array.isArray(labels)">
            <div class='table-head mb-main grid h-8' :class="`grid-cols-${labels.length}`">
                <div v-for="label in labels" class='th text-center grey-label font-small col-span-1'>{{ label }}</div>
            </div>
        </template>
        <template v-else>
            <div class='table-head grid' 
                :class="`${colsClass} h-[${hasAdditionals ? 4 : 2}rem] ${headerMargin}`">
                <div v-for="[label, options] in Object.entries(labels)" class='th text-center grey-label font-small flex justify-center items-center relative group' 
                        :class="`col-span-${options.size} ${options.hide}`" >
                    <div @click="options.attribute && !options.additionals ? order_by(options) : ()=>{}" class="relative" :class="{'cursor-pointer': options.attribute, 'w-full': options.additionals, 'self-end ': hasAdditionals && !options.additionals}">
                        <p :class="{'font-bold': options.additionals}">
                            {{!label.startsWith("@") ? label : ''}}
                        </p>
                        <div class="grid grid-cols-2 mt-4 cursor-pointer" v-if="options.additionals">
                            <div @click="order_by(y)" v-for="[x, y] in Object.entries(options.additionals)" class="col-span-1">
                                {{ x }}
                            </div>
                        </div>
                        <div v-if="options.attribute && currentOrder?.attribute == joinAttribute(options.attribute.name)" class="images flex max-h-[1.4rem] absolute top-[0.3rem] right-[-1.3rem]">
                            <img class='filter-light-grey' :src="currentOrder.ascending ? arrowUp : arrowDown"/>
                        </div>
                        <slot v-if="options.hint" :name="options.hintName || label"/>
                    </div>
                    <div v-if="options.attribute && options.attribute.name && ( options.attribute.filter || options.attribute.total )" class="absolute bottom-[-100%]">
                        <input v-if="options.attribute.filter" @input="({target}) => filter(options.attribute?.name as string, target.value)" class="border-main px-2" type="text" placeholder="Filtrar"/>
                    </div>
                </div>
            </div>
        </template>
            
        </template>
        <div 
            ref="table" 
            class="table-body flex flex-col -mr-2 pr-2" 
            :class="{'gap-main-small' : gapSmall, 'gap-small' : !gapSmall, 'no-scrollbar' : overflowHidden, 'overflow-auto' : overflowAuto }"
            :style="{'max-height': `${maxHeight}rem`, 'height': `${noSetHeight ? 'auto' : `${maxHeight}rem`}`}">
            <slot />
        </div>
        <BaseItem v-if="hasTotal" background="dark-blue-bg" :class="gapSmall ? 'mt-2' : 'mt-4'" :height="4" :size="columns">
            <div v-for="[label, options] in Object.entries(labels)" class="font-small flex justify-center items-center text-white" :class="`col-span-${options.size} ${options.hide}`">
                <div v-if="options.attribute && options.attribute.name && ( options.attribute.filter || options.attribute.total )">
                    <p v-if="options.attribute.total" class="border-main whitespace-nowrap px-2">{{ totais[label] }}</p>
                </div>
                
                <div v-else-if="options.additionals" v-for="[x, y] in Object.entries(options.additionals)" class="font-small flex justify-center items-center text-white w-full">
                    <p v-if="y.attribute.total" class="border-main whitespace-nowrap px-2">{{ totais[`${x}-${label}`] }}</p>
                </div>

               
            </div>
        </BaseItem>
    </div>
</template>